import { axiosInterceptor } from './axiosInterceptor'

export default (token, shop, host) => {
  const axiosWithToken = axiosInterceptor(shop, host)
  return axiosWithToken.request({
    url: `/getCheckoutProfiles`,
    method: 'get',    
    headers: {
        'X-Shopify-Access-Token': token,
        'X-Shopify-Shop-Domain': shop,
    },
  })
}