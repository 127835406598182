import React, { useState, useCallback, useEffect } from "react"
import {
  Page,
  Select,
  FormLayout,
  Badge,
  Button,
  Stack,
  Modal,
} from "@shopify/polaris"
import { withFirebase } from "../../../providers/firebase"
import CustomDesign from "../customdesign/CustomDesign"
import getCheckoutProfiles from "../../../helpers/getCheckoutProfiles"
function Design(props) {
  const { token, shop, host, location } = props
  const backLink = location && location.state && location.state.backLink
  const [profilesData, setProfilesData] = useState(null)
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    async function fetchData() {
      let checkoutProfiles
      try {
        checkoutProfiles = await getCheckoutProfiles(token, shop, host)
        console.log("checkoutProfiles", checkoutProfiles)
      } catch (e) {
        console.log(e)
      }
      if (checkoutProfiles && checkoutProfiles.data) {
        setProfilesData(checkoutProfiles.data)
      }
    }
    fetchData()
  }, [])
  
  const handleSelectChange = useCallback((value) => setSelected(value), [])

  
  const options = profilesData?.checkoutProfiles?.edges
    ? profilesData.checkoutProfiles.edges
        .sort((a, b) => {
          return a.node.isPublished && !b.node.isPublished ? -1 : 1
        })
        .map((profile) => {
          return {
            label: profile.node.name,
            value: profile.node.id,
            prefix: profile.node.isPublished ? (
              <Badge status="success">Live</Badge>
            ) : null,
          }
        })
    : []
  return (
    <Page
      title="Checkout branding"
      breadcrumbs={[{ content: "Settings", url: "settings"===backLink ? "/app/settings":"/app/offers/common/checkoutExtensionGuide/checkoutExtensionGuide" }]}
    >
      <FormLayout>
        <Stack alignment="trailing">
          <Stack.Item fill>
            <div style={{ maxWidth: "310px" }}>
              <Select
                label="Choose the checkout profile to customize"
                options={options}
                onChange={handleSelectChange}
                value={selected}
              />
            </div>
          </Stack.Item>
        </Stack>
        {profilesData?.checkoutProfiles?.edges ? (
          <CustomDesign
            checkoutProfileId={
              selected
                ? selected
                : profilesData?.checkoutProfiles?.edges[0].node.id
            }
            liveProfile={
              selected === null ||
              (selected &&
                profilesData?.checkoutProfiles?.edges.find(
                  (profile) => profile.node.id === selected
                ).node.isPublished)
            }
            token={token}
            shop={shop}
            host={host}
            location={location}
            key={selected}
          />
        ) : null}
      </FormLayout>
    </Page>
  )
}

export default withFirebase(Design)
